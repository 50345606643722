import React from 'react';

import { UnderConstruction } from '../components'
import Layout from '../layouts';

const ProjectsPage = () => {
  return (
    <Layout pageTitle="Projects">
      <UnderConstruction />
    </Layout>
  );
}

export default ProjectsPage